<template>
  <div>
    <b-overlay
        id="overlay-background"
        :show="!loaded"
        variant="white"
        opacity="1"
        rounded="sm"
    >
      <div v-if="loaded">
        <swiper
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >

          <swiper-slide v-for="(img,index) in images" :key="index">
            <div class="realEstate-image-swiper position-relative  rounded" style="height: 250px;overflow: hidden">
              <template>
                <b-img
                    @click="imageModal(img)"
                    :src="mergeSrcUrl(immosharesWebiste,img)"
                    style="max-height: 250px;display: block;margin: 0 auto"
                />
              </template>


              <div class="options">
                <b-button v-if="index > 0" variant="gradient-primary" class="btn-icon"
                          @click="moveToIndex(index,index-1)">
                  <feather-icon icon="ArrowLeftIcon"/>
                </b-button>
                <b-button variant="gradient-danger" class="btn-icon mx-1" @click="removeIndex(index)">
                  <feather-icon icon="ArchiveIcon"/>
                </b-button>
                <b-button v-if="index < images.length - 1" variant="gradient-primary" class="btn-icon"
                          @click="moveToIndex(index,index+1)">
                  <feather-icon icon="ArrowRightIcon"/>
                </b-button>
              </div>
            </div>

          </swiper-slide>


          <div
              slot="pagination"
              class="swiper-pagination"
          />
        </swiper>
      </div>
      <b-modal body-bg-variant="black" hide-backdrop :id="infoModal.id" title="Confirmation" hide-header-close
               hide-header
               size="lg"
               centered
               hide-footer @hide="resetInfoModal">
        <div style="align-content: center;text-align: center">
          <b-img v-if="infoModal.img!=null"
                 style="max-height: 90vh"
                 :src="mergeSrcUrl(immosharesWebiste,infoModal.img)"
                 fluid
                 alt="Bild nicht verfuegbar"/>


        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateImageSwiper',
  props: {
    value: {required: true},

  },
  watch: {
    value: {
      handler() {
        let $this = this;
        this.loaded = false;
        setTimeout(()=>{
          $this.loaded = true;
          this.onValueUpdated();
        },1000)
      },
      immediate: true
    }
  },
  data: function () {
    return {
      loaded:false,
      infoModal: {
        id: 'imageModal',
        img: null,
        content: ''
      },
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('data', {website: 'getOrganizationWebSite', immosharesWebiste: 'getRealEstatesWebSite'}),
    images: {
      get() {
        if (this.value == null) return null
        return this.value.filter(attachment => attachment.attachmentGroup === 'BILD'
            || attachment.attachmentGroup === 'TITELBILD'
            || attachment.attachmentGroup === 'GRUNDRISS'
        )
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    imageModal(img) {
      this.infoModal.img = img;
      this.$root.$emit('bv::show::modal', this.infoModal.id)
    },
    moveToIndex(index, toIndex) {
      let images = [...this.images];
      images.move(index, toIndex);
      this.images = images;
      this.onValueUpdated();
    },
    removeIndex(index) {
      console.log("remove image " + index)
      let images = [...this.images];
      images.splice(index, 1);
      this.images = images;
      this.onValueUpdated();
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-image-swiper .options {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
}

.realEstate-image-swiper:hover .options {
  display: block;
}
</style>
